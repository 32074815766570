
  
  .masonry-grid-item {
    break-inside: avoid;
    margin-bottom: 1rem;
    width: 100%;
  }
  
  .masonry-grid {
    column-count: 1;
    column-gap: 1rem;
  }
  
  @media (min-width: 768px) {
    .masonry-grid {
      column-count: 2;
    }
  }
  
  @media (min-width: 1024px) {
    .masonry-grid {
      column-count: 3;
    }
  }
  
  @media (min-width: 1280px) {
    .masonry-grid {
      column-count: 4;
    }
  }
.absolute {
    position: absolute;
    z-index: 10; /* Ensures it appears above the map */
  }
  .bg-opacity-75 {
    background-color: rgba(255, 255, 255, 0.75); /* Semi-transparent background */
  }

  /* Custom CSS for overlay container */
.map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    max-height: 200px; /* Adjust this as needed */
    padding: 10px;
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
    overflow: hidden;
  }
  
  /* CSS for scrollable list of places */
  .scrollable-places {
    max-height: 100%;
    overflow-y: auto; /* Enables vertical scrolling */
  }

  @media (max-width: 767px) { /* 640px is a common breakpoint for 'sm' size */
    .full-screen-map {
      width: 100vw;
      height: 50vh;
    }
  }

  .wrapper {
  position: relative;
}
.full-screen-map {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.content-container {
  width: 40%;
  position: relative;
  z-index: 2;
  /* Add more styling as needed (e.g., padding, margin, background) */
  /* Ensure it takes the full width and has enough space for scrolling */
}