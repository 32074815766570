.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.animation-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.white-circle {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 70%);
  animation: pulse 3s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}

.hero-text {
  z-index: 2;
  color: white;
  padding: 20px;
}

.mission-statement {
  font-size: 1.5rem;
  margin-bottom: 30px;
  font-weight: medium;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.button {
  background-color: white;
  color: black;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #ddd;
}
