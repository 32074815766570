@tailwind base;
@tailwind components;
@tailwind utilities;
.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* You can adjust the max-width as needed for your layout */
    max-width: 100%;
  }.input-style {
    background-color: #F3F4F6; /* Equivalent to bg-gray-50 */
    border: 1px solid #D1D5DB; /* Equivalent to border-gray-300 */
    color: #1F2937; /* Equivalent to text-gray-900 */
    border-radius: 0.5rem; /* Equivalent to rounded-lg */
    display: block;
    width: 100%; /* Equivalent to w-full */
    padding: 0.625rem; /* Equivalent to p-2.5 */
    /* For the focus styles, you need to use the :focus pseudo-class */
  }
  
  .input-style:focus {
    --tw-ring-opacity: 1; /* Assuming you have this custom property defined */
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)); /* Equivalent to ring-blue-500 */
    --tw-ring-offset-width: 2px;
    --tw-ring-offset-color: #fff;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    border-color: #3B82F6; /* Equivalent to border-blue-500 */
  }
  
  /* Dark mode styles */
  @media (prefers-color-scheme: dark) {
    .input-style {
      background-color: #374151; /* Equivalent to dark:bg-gray-700 */
      border-color: #4B5563; /* Equivalent to dark:border-gray-600 */
      color: #F3F4F6; /* Equivalent to dark:text-white */
      --tw-placeholder-opacity: 1; /* Assuming you have this custom property defined */
      --tw-placeholder-color: rgba(156, 163, 175, var(--tw-placeholder-opacity)); /* Equivalent to dark:placeholder-gray-400 */
    }
  
    .input-style::placeholder {
      color: var(--tw-placeholder-color);
    }
  
    .input-style:focus {
      --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)); /* Equivalent to dark:focus:ring-blue-500 */
      border-color: #3B82F6; /* Equivalent to dark:focus:border-blue-500 */
    }
  }
